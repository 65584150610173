import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import FintechSvg from 'svgs/blog/fintech.svg';

export const BANKING_SOLUTIONS = [
    {
        title: 'fintech-page.tradingPlatforms',
        text: 'fintech-page.tradingPlatformsText',
        firstLinkHref: `/${PATHS.CONTACT_US}`,
        secondLinkHref: `/${PATHS.ESTIMATE_THE_PROJECT}`,
    },
    {
        title: 'fintech-page.financialManagement',
        text: 'fintech-page.financialManagementText',
        firstLinkHref: `/${PATHS.CONTACT_US}`,
        secondLinkHref: `/${PATHS.ESTIMATE_THE_PROJECT}`,
    },
    {
        title: 'fintech-page.paymentSolutions',
        text: 'fintech-page.paymentSolutionsText',
        firstLinkHref: `/${PATHS.CONTACT_US}`,
        secondLinkHref: `/${PATHS.ESTIMATE_THE_PROJECT}`,
    },
];

export const PERKS = [
    {
        title: 'fintech-page.securityTitle',
        text: 'fintech-page.securityText',
    },
    {
        title: 'fintech-page.assuranceTitle',
        text: 'fintech-page.assuranceText',
    },
    {
        title: 'fintech-page.efficiencyTitle',
        text: 'fintech-page.efficiencyText',
    },
    {
        title: 'fintech-page.complianceTitle',
        text: 'fintech-page.complianceText',
    },
    {
        title: 'fintech-page.innovationTitle',
        text: 'fintech-page.innovationText',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.FINTECH],
    button: {
        to: `${PATHS.BLOG}/${PATHS.FINTECH}`,
        label: 'fintech-page.footer.button',
    },
    categoryBanner: {
        title: 'fintech-page.footer.title',
        description: 'fintech-page.footer.description',
        svg: FintechSvg,
    },
};
