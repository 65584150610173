import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const FintechCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ctaExtendedTitle">
            <p>
                Empower your business with cutting-edge software designed to
                enhance customer experience, streamline operations, and ensure
                compliance.
            </p>
            <ul>
                <li>Free consultation for bespoke fintech solutions</li>
                <li>
                    Explore how custom software can drive efficiency and growth
                    in the financial sector
                </li>
                <li>
                    Expertise in digital banking, payment processing, and
                    investment platforms
                </li>
                <li>
                    Enhance security and compliance with advanced technologies
                </li>
                <li>Optimize financial operations with data-driven insights</li>
            </ul>
        </CtaSectionExtended>
    );
};
